import React, { useState } from 'react';

import classNames from 'classnames';
import { debounce } from 'debounce';
import propTypes from 'prop-types';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';

import { refreshSession as refreshSessionAction } from '../../../../../../actions/session';
import { getSettings, getUser, hasLicences } from '../../../../../../actions/session-selector';
import { licences } from '../../../../../../api/licences';
import { updateTenantSettings } from '../../../../../../api/tenant/update-settings';
import CurrencyInput from '../../../../../../components/common/currency-input';
import Tooltip from '../../../../../../components/common/tooltip';
import { number } from '../../../../../../format-numbers';
import { getMoneySymbol } from '../../../../../money';
import { MessageType, showToastMessage } from '../../../../../toast/toast';
import { ToggleAutoQuoting } from '../../../../../update-ratesheet/toggle-auto-quoting';

const UnsolicitedRatesheetSettingsComponent = injectIntl(
  ({
    hasUnsolicitedRatesheetLicence,
    isUnsolicitedRatesheetDisabled,
    unsolicitedRatesheetMaxBankAmount,
    refreshSession,
    user,
    intl,
  }) => {
    const [isUnsolicitedRatesheetChecked, setUnsolicitedRatesheetAvailability] = useState(
      !isUnsolicitedRatesheetDisabled,
    );

    const [isUnsolicitedRatesheetAvailabilityLoading, setIsUnsolicitedRatesheetAvailabilityLoading] = useState(false);

    if (!hasUnsolicitedRatesheetLicence) return null;

    const onDigitalRatesheetAvailabilityChange = async ({ target }) => {
      const { checked: isDigitalRatesheetOn } = target;
      try {
        const unsolicitedRatesheetDisabled = !isDigitalRatesheetOn;

        setIsUnsolicitedRatesheetAvailabilityLoading(true);

        await updateTenantSettings({ unsolicitedRatesheetDisabled });

        setIsUnsolicitedRatesheetAvailabilityLoading(false);
        setUnsolicitedRatesheetAvailability(isDigitalRatesheetOn);

        await refreshSession();
      } catch (err) {
        showToastMessage('There was an error updating the setting', MessageType.ERROR);
      }
    };

    const onMaxAmountChange = async ({ floatValue: unsolicitedRatesheetMaxBankAmount }) => {
      try {
        await updateTenantSettings({
          unsolicitedRatesheetMaxBankAmount:
            unsolicitedRatesheetMaxBankAmount === undefined ? null : unsolicitedRatesheetMaxBankAmount,
        });

        await refreshSession();
      } catch (err) {
        showToastMessage('There was an error updating the setting', MessageType.ERROR);
      }
    };

    const currencySymbol = getMoneySymbol({ currency: user.currency, short: true });

    return (
      <Row
        element="section"
        aria-label="tenant settings"
        className="bank-unsolicited-ratesheet-settings"
        justifyContentSpaceBetween
      >
        <Tooltip id="unsolicitedRatesheetMaxBankAmountPopoverTitle" placement="right">
          <Column className="bank-unsolicited-ratesheet-settings-max-amount">
            <label htmlFor="unsolicited-ratesheet-max-bank-amount">
              <FormattedMessage id="unsolicitedRatesheetMaxBankAmountLabel" />
            </label>
            <CurrencyInput
              id="unsolicited-ratesheet-max-bank-amount"
              decimalSeparator="."
              value={unsolicitedRatesheetMaxBankAmount > 0 ? unsolicitedRatesheetMaxBankAmount : ''}
              allowNegative={false}
              decimalScale={number.maximumFractionDigits}
              prefix={currencySymbol}
              placeholder="unsolicitedRatesheetMaxBankAmountPlaceholder"
              disabled={!isUnsolicitedRatesheetChecked}
              onValueChange={debounce(onMaxAmountChange, 1000)}
            />
          </Column>
        </Tooltip>

        <Row className="bank-unsolicited-ratesheet-settings-toggles">
          <ToggleAutoQuoting />
          <div
            className={classNames('toggle-bank-unsolicited-ratesheet', {
              'is-loading': isUnsolicitedRatesheetAvailabilityLoading,
            })}
          >
            <FormattedMessage
              tagName="span"
              id={isUnsolicitedRatesheetChecked ? 'unsolicitedRatesheetOn' : 'unsolicitedRatesheetOff'}
            />
            <Toggle
              disabled={isUnsolicitedRatesheetAvailabilityLoading}
              checked={isUnsolicitedRatesheetChecked}
              icons={false}
              onChange={onDigitalRatesheetAvailabilityChange}
            />
          </div>
        </Row>
      </Row>
    );
  },
);

UnsolicitedRatesheetSettingsComponent.propTypes = {
  hasUnsolicitedRatesheetLicence: propTypes.bool,
  isUnsolicitedRatesheetDisabled: propTypes.bool,
};

const mapStateToProps = (state) => {
  const hasUnsolicitedRatesheetLicence = hasLicences(licences.unsolicitedRatesheet)(state);
  const tenantSettings = getSettings(state);

  const isUnsolicitedRatesheetDisabled =
    tenantSettings && 'unsolicitedRatesheetDisabled' in tenantSettings
      ? tenantSettings.unsolicitedRatesheetDisabled
      : false;

  return {
    user: getUser(state),
    hasUnsolicitedRatesheetLicence: hasUnsolicitedRatesheetLicence,
    isUnsolicitedRatesheetDisabled: !hasUnsolicitedRatesheetLicence || isUnsolicitedRatesheetDisabled,
    unsolicitedRatesheetMaxBankAmount: tenantSettings && tenantSettings.unsolicitedRatesheetMaxBankAmount,
  };
};

const mapDispatchToProps = { refreshSession: refreshSessionAction };

export const UnsolicitedRatesheetSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnsolicitedRatesheetSettingsComponent);

import React from 'react';

import { Formik } from 'formik';
import { func, number, shape, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import useSWR from 'swr';
import * as Yup from 'yup';

import { interests } from '../../../../../../api/holding/codes';
import { getTradesMaturingToday, getTradesMaturingTodayUrl } from '../../../../../../api/holding/maturing-today';
import {
  ConfirmationPopover,
  FormikCurrencyInput,
  FormikSelectField,
  ValidatedFormikField,
} from '../../../../../../components/common';
import { toCurrency, toNumberFormat } from '../../../../../../format-numbers';
import toFloat from '../../../../../../parse-float';
import { ImperiumIntlProvider } from '../../../../../intl-provider';
import { splitTenor } from '../../../../../Tenors/TenorItem';

export const initialFormValues = {
  additionalFunds: '0',
};

export const CollationOfRatesheetsReinvestmentForm = injectIntl(
  ({
    tenor,
    rate,
    issuer,
    onCancelClick,
    onCreateTradeSubmit,
    popoverContainer,
    onReinvestRfqClick,
    unsolicitedRatesheetMaxBankAmount,
    intl,
  }) => {
    const { data: maturingTradesToday } = useSWR(getTradesMaturingTodayUrl, getTradesMaturingToday);

    const { time, unit } = splitTenor(tenor);

    return (
      <ImperiumIntlProvider>
        <Formik
          validationSchema={buildValidationSchema({ intl, maturingTradesToday, unsolicitedRatesheetMaxBankAmount })}
          onSubmit={onCreateTradeSubmit}
          initialValues={initialFormValues}
          validateOnChange
        >
          {({ errors, touched, isSubmitting, dirty, isValid, handleSubmit, values, setFieldValue, setValues }) => {
            const isDisabled = isSubmitting || !dirty || !isValid;
            const isRequestRfqDisabled = !values.rootTradeId || !values.interestPaid || !values.allocationCode;
            const selectedSourceTrade = maturingTradesToday?.find(({ id }) => id === values.rootTradeId);

            return (
              <Column>
                <Column>
                  <ValidatedFormikField
                    autoFocus
                    name="rootTradeId"
                    labelId="sourceTrade"
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={maturingTradesToday?.map(({ id: value, principal, issuer }) => ({
                      value,
                      label: `${issuer.name} - ${toNumberFormat({ value: principal })}`,
                    }))}
                    onChange={(value) => {
                      const maturingTrade = maturingTradesToday.find(({ id }) => id === value);

                      setValues({
                        allocationCode: maturingTrade.allocationCode,
                        interestPaid: maturingTrade.interestPaidCode,
                      });
                    }}
                    isLoading={!maturingTradesToday}
                  />
                  <ValidatedFormikField
                    name="interestPaid"
                    labelId="selectInterestPaid"
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={interests.map(({ code: value, label: id }) => ({
                      value,
                      label: intl.formatMessage({ id }),
                    }))}
                  />
                  <ValidatedFormikField
                    name="allocationCode"
                    labelId="holdingAllocationCode"
                    touched={touched}
                    errors={errors}
                    disabled
                  />
                  <ValidatedFormikField
                    name="additionalFunds"
                    labelId="additionalFunds"
                    component={FormikCurrencyInput}
                    allowNegative={false}
                    touched={touched}
                    errors={errors}
                    aria-label="additional funds"
                  />
                </Column>
                <Row className="actions" justifyContentEnd>
                  <ConfirmationPopover
                    buttonLabelId="unsolicitedRatesheetDeal"
                    confirmationMessageId="unsolicitedRatesheetDealReinvestmentConfirmation"
                    confirmationMessageValues={{
                      sourceTrade:
                        selectedSourceTrade &&
                        `${selectedSourceTrade.issuer.name} ${toCurrency({ value: selectedSourceTrade.principal })}`,
                      b: (msg) => <b>{msg}</b>,
                    }}
                    disabled={isDisabled}
                    onConfirm={handleSubmit}
                    className="btn-solid-primary"
                    popoverClass="light"
                    isConfirmingFromOutside={isSubmitting}
                    container={popoverContainer?.current}
                  />
                  <ConfirmationPopover
                    buttonLabelId="unsolicitedRatesheetRequestRfq"
                    confirmationMessageId="areYouSure"
                    popoverClass="light"
                    className="btn-solid-primary"
                    disabled={isRequestRfqDisabled}
                    onConfirm={() => {
                      onReinvestRfqClick({
                        id: values.rootTradeId,
                        issuerId: issuer.id,
                        maturities: [
                          {
                            time,
                            unit,
                          },
                        ],
                        interestPaid: values.interestPaid,
                        additionalFunds: values.additionalFunds,
                        allocationCode: values.allocationCode,
                      });
                    }}
                  />
                  <Button onClick={onCancelClick}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Row>
              </Column>
            );
          }}
        </Formik>
      </ImperiumIntlProvider>
    );
  },
);

CollationOfRatesheetsReinvestmentForm.propTypes = {
  onCreateTradeSubmit: func,
  onCancelClick: func,
  onReinvestRfqClick: func,
  popoverContainer: shape(),
  tenor: string,
  rate: number,
  unsolicitedRatesheetMaxBankAmount: number,
  issuer: shape(),
  intl: shape(),
};

const buildValidationSchema = ({ intl, unsolicitedRatesheetMaxBankAmount, maturingTradesToday }) =>
  Yup.object().shape({
    rootTradeId: Yup.number()
      .required(intl.formatMessage({ id: 'required' }))
      .test('maxPrincipal', intl.formatMessage({ id: 'unsolicitedRatesheetMaxAmountReached' }), function (value) {
        if (!maturingTradesToday.length) {
          return true;
        }

        const maturingTrade = maturingTradesToday.find(({ id }) => id === this.parent.rootTradeId);

        return unsolicitedRatesheetMaxBankAmount > 0 && maturingTrade
          ? toFloat(maturingTrade.principal) <= unsolicitedRatesheetMaxBankAmount
          : true;
      }),
    interestPaid: Yup.string().required(intl.formatMessage({ id: 'required' })),
    allocationCode: Yup.string().required(intl.formatMessage({ id: 'required' })),
    additionalFunds: Yup.string().test(
      'maxAmountReachedWithAdditionalFundsPrincipal',
      intl.formatMessage({ id: 'unsolicitedRatesheetMaxAmountReachedWithAdditionalFunds' }),
      function (value) {
        if (!maturingTradesToday.length || !+value) {
          return true;
        }

        const maturingTrade = maturingTradesToday.find(({ id }) => id === this.parent.rootTradeId);

        let principal = this.parent.additionalFunds ? +this.parent.additionalFunds : 0;

        if (maturingTrade && maturingTrade.principal) {
          principal += maturingTrade.principal;
        }

        return unsolicitedRatesheetMaxBankAmount > 0 && maturingTrade
          ? toFloat(principal) <= unsolicitedRatesheetMaxBankAmount
          : true;
      },
    ),
  });

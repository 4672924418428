import React from 'react';

import { Formik } from 'formik';
import { func, number, shape, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';

import { generalAllocationCode, interests } from '../../../../../../api/holding/codes';
import {
  ConfirmationPopover,
  FormikCurrencyInput,
  FormikSelectField,
  ValidatedFormikField,
} from '../../../../../../components/common';
import { getBusinessDate, getDateInTimeFrameFrom, today } from '../../../../../../date';
import { couponFormat, toCurrency, toNumberFormat } from '../../../../../../format-numbers';
import toFloat from '../../../../../../parse-float';
import { ImperiumIntlProvider } from '../../../../../intl-provider';
import { splitTenor } from '../../../../../Tenors/TenorItem';

export const buildInitialFormValues = ({ time, unit }) => ({
  principal: '',
  interestPaid: unit === 'M' && time <= 12 ? 'Z' : '',
  allocationCode: generalAllocationCode.code,
});

const buildValidationSchema = ({ intl, unsolicitedRatesheetMaxBankAmount }) =>
  Yup.object().shape({
    interestPaid: Yup.string().required(intl.formatMessage({ id: 'required' })),
    allocationCode: Yup.string().required(intl.formatMessage({ id: 'required' })),
    principal: Yup.string()
      .required(intl.formatMessage({ id: 'required' }))
      .test('maxPrincipal', intl.formatMessage({ id: 'unsolicitedRatesheetMaxAmountReached' }), (value) =>
        unsolicitedRatesheetMaxBankAmount > 0 ? toFloat(value) <= unsolicitedRatesheetMaxBankAmount : true,
      ),
  });

export const CreateTradeFromRatePopoverForm = injectIntl(
  ({
    allocationCodes,
    onAddAllocationCode,
    tenor,
    rate,
    issuer,
    onCancelClick,
    onCreateTradeSubmit,
    popoverContainer,
    onCreateRfqClick,
    unsolicitedRatesheetMaxBankAmount,
    intl,
  }) => {
    const { time, unit } = splitTenor(tenor);

    const tenorMaturityDate = getBusinessDate({
      date: getDateInTimeFrameFrom(today(), time, unit),
    }).toDate();

    return (
      <ImperiumIntlProvider>
        <Formik
          validationSchema={buildValidationSchema({ intl, unsolicitedRatesheetMaxBankAmount })}
          onSubmit={onCreateTradeSubmit}
          initialValues={buildInitialFormValues({ time, unit })}
        >
          {({ errors, touched, isSubmitting, dirty, isValid, handleSubmit, values }) => {
            const isDisabled = isSubmitting || !dirty || !isValid;
            const isRequestRfqDisabled = !values.principal || !values.interestPaid || !values.allocationCode;

            return (
              <Column element="section" role="region" aria-label="new funds form">
                <Column>
                  <ValidatedFormikField
                    aria-label="principal"
                    autoFocus
                    name="principal"
                    labelId="principal"
                    component={FormikCurrencyInput}
                    touched={touched}
                    errors={errors}
                    allowNegative={false}
                  />
                  <ValidatedFormikField
                    name="interestPaid"
                    labelId="selectInterestPaid"
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={interests.map(({ code: value, label: id }) => ({
                      value,
                      label: intl.formatMessage({ id }),
                    }))}
                  />
                  <ValidatedFormikField
                    name="allocationCode"
                    labelId="holdingAllocationCode"
                    allowCreate
                    onChange={(value) => {
                      if (allocationCodes.find((option) => option.value === value)) {
                        return;
                      }

                      onAddAllocationCode([
                        ...allocationCodes,
                        {
                          value,
                          label: value,
                        },
                      ]);
                    }}
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={allocationCodes}
                  />
                </Column>
                <Row className="actions" justifyContentEnd>
                  <ConfirmationPopover
                    buttonLabelId="unsolicitedRatesheetDeal"
                    confirmationMessageId="unsolicitedRatesheetDealConfirmation"
                    confirmationMessageValues={{
                      principal: toCurrency({ value: toFloat(values.principal) }),
                      rate: toNumberFormat({ value: rate, ...couponFormat }),
                      maturityDate: tenorMaturityDate,
                      b: (msg) => <b>{msg}</b>,
                      attention: (msg) => <b className="attention">{msg}</b>,
                    }}
                    disabled={isDisabled}
                    onConfirm={handleSubmit}
                    className="btn-solid-primary"
                    popoverClass="dark"
                    isConfirmingFromOutside={isSubmitting}
                    container={popoverContainer?.current}
                  />
                  <ConfirmationPopover
                    buttonLabelId="unsolicitedRatesheetRequestRfq"
                    confirmationMessageId="areYouSure"
                    popoverClass="dark"
                    className="btn-solid-primary"
                    disabled={isRequestRfqDisabled}
                    onConfirm={() => {
                      onCreateRfqClick({
                        issuerId: issuer.id,
                        maturities: [
                          {
                            time,
                            unit,
                          },
                        ],
                        ...values,
                      });
                    }}
                  />
                  <Button onClick={onCancelClick}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Row>
              </Column>
            );
          }}
        </Formik>
      </ImperiumIntlProvider>
    );
  },
);

CreateTradeFromRatePopoverForm.propTypes = {
  onCreateTradeSubmit: func,
  onCancelClick: func,
  onAddAllocationCode: func,
  popoverContainer: shape(),
  tenor: string,
  rate: number,
  unsolicitedRatesheetMaxBankAmount: number,
  issuer: shape(),
  intl: shape(),
};

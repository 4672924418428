import './header.scss';

import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { toggleAddTradeSidebar } from '../../actions/portfolio';
import { getRootPath, hasLicences, hasRequiredRole } from '../../actions/session-selector';
import logoIcon from '../../assets/icons/common/logo_icon.png';
import { AddTrade } from '../../containers';
import { AppContext } from '../../containers/app/AppContext';
import { isPast } from '../../date';
import { routes } from '../../routes';
import { Drawer, licences, Protect, roles, Tooltip, tourElements } from '../common';
import { Loadable } from '../common/loadable';
import { HeaderFilters } from '../header/header-filters';
import { ControlCenterNotifications } from './ControlCenterNotifications';
import { NewRfqButton } from './NewRfqButton';

const ADD_TRADE_COMMAND = 'ctrl+alt+t';
const ADD_TRADE_COMMAND_MAC = 'cmd+alt+t';
const ADD_RFQ_COMMAND = 'ctrl+alt+r';
const ADD_RFQ_COMMAND_MAC = 'cmd+alt+r';
const HANDLE_KEYS = [ADD_TRADE_COMMAND, ADD_TRADE_COMMAND_MAC, ADD_RFQ_COMMAND, ADD_RFQ_COMMAND_MAC];

const rfqsRootPath = '/portfolio/rfqs';
const portfolioRootPaths = ['/', rfqsRootPath];

const isEqual = (locationPath, matchPath) => locationPath === matchPath;
const startsWith = (locationPath, matchPath) => locationPath.startsWith(matchPath);

const Header = ({ items, user, hasFinanceRole, navigate, rootPath, actions }) => {
  const { isAddTradeOpen, setIsAddTradeOpen, logo, isLoading } = useContext(AppContext);
  const location = useLocation();

  const contentHeadersByLocation = [
    {
      path: routes.root,
      shouldRenderHeader: (locationPath, itemPath) =>
        isEqual(locationPath, itemPath) && portfolioRootPaths.includes(rootPath),
      component: <HeaderFilters location={location} hideAsAt={rootPath === rfqsRootPath} />,
    },
    {
      path: routes.holdings.list,
      shouldRenderHeader: isEqual,
      component: <HeaderFilters location={location} />,
    },
    {
      path: routes.rfqsRoot,
      shouldRenderHeader: startsWith,
      component: <HeaderFilters location={location} hideAsAt />,
    },
    {
      path: routes.portfolioRoot.base,
      shouldRenderHeader: startsWith,
      component: <HeaderFilters location={location} />,
    },
  ];
  const headerItem = contentHeadersByLocation.find((item) => item.shouldRenderHeader(location.pathname, item.path));

  const onKeyPressed = (key) => {
    switch (key) {
      case ADD_RFQ_COMMAND:
      case ADD_RFQ_COMMAND_MAC:
        navigate(routes.holdings.newRfq);
        break;
      case ADD_TRADE_COMMAND:
      case ADD_TRADE_COMMAND_MAC:
        setIsAddTradeOpen(true);
        break;
      default:
        break;
    }
  };

  const renderActionButtons = () => {
    if (!shouldShowMenuOptions(user)) {
      return null;
    }

    if (isPast(user.passwordExpiresAt) && location.pathname === routes.root) {
      return null;
    }

    const routesWithoutActionButtons = [routes.holdings.newRfq, routes.incoming.base];

    // we're using "includes" instead of "===" as we also want to match routes like /incoming-rfqs/current/:uuid
    if (routesWithoutActionButtons.some((route) => location.pathname.includes(route))) {
      return null;
    }

    const isMac = navigator.platform.includes('Mac');

    return (
      <ul className="nav navbar-nav navbar-right" data-tour={tourElements.actions}>
        <Protect user={user} requiredTo={roles.admin} licence={licences.unsolicitedRatesheet}>
          <li className="notifications">
            <ControlCenterNotifications />
          </li>
        </Protect>
        <Protect user={user} requiredTo={roles.finance} licence={licences.portfolio}>
          <li className="header-dropdown">
            <Tooltip
              id="add-trade"
              tooltipComponent={<span className="shortcut">{isMac ? '⎇⌘T' : 'Ctrl+alt+T'}</span>}
              placement="bottom"
            >
              <button className="btn btn-default" type="button" onClick={() => setIsAddTradeOpen(true)}>
                <FormattedMessage id="menu.addTradeShortcut" />
              </button>
            </Tooltip>
          </li>
        </Protect>
        {hasFinanceRole && (
          <li className="header-dropdown">
            <NewRfqButton />
          </li>
        )}
      </ul>
    );
  };

  return (
    <div>
      {hasFinanceRole && (
        <React.Fragment>
          <KeyboardEventHandler handleKeys={HANDLE_KEYS} onKeyEvent={onKeyPressed} />
          <Drawer
            titleId="addNewTrade"
            className="light"
            open={isAddTradeOpen}
            onClose={() => setIsAddTradeOpen(false)}
            width="30vw"
          >
            <AddTrade setIsAddTradeOpen={setIsAddTradeOpen} />
          </Drawer>
        </React.Fragment>
      )}
      <nav className="header-container navbar navbar-default navbar-fixed-top">
        <div className="container-fluid">
          {renderMobileNavHeader(user)}
          <div id="navbar" className="navbar-collapse collapse">
            {renderNavHeader({ user, logo, isLoading })}
            <div className="nav navbar-nav header-items">{headerItem && headerItem.component}</div>
            {renderActionButtons()}
          </div>
        </div>
      </nav>
    </div>
  );
};

const onHamburgerClick = () => {
  document.body.classList.toggle('nav-toggle');
};

const shouldShowMenuOptions = (user) => user && user.globalRole !== 'bank';

const renderMobileNavHeader = (user) => {
  if (!shouldShowMenuOptions(user)) {
    return null;
  }

  return (
    <div className="navbar-header">
      <div id="mobile-menu">
        <div className="left-nav-toggle">
          <button type="button" onClick={onHamburgerClick}>
            <i className="glyphicon glyphicon-menu-hamburger" />
          </button>
        </div>
      </div>
    </div>
  );
};

const renderNavHeader = ({ user, logo, isLoading }) => {
  if (!shouldShowMenuOptions(user)) {
    return null;
  }

  return (
    <div className="left-nav-toggle">
      <button type="button" onClick={onHamburgerClick} data-tour="hamburger-button">
        <i className="glyphicon glyphicon-menu-hamburger" />
      </button>
      {renderLogo({ user, logo, isLoading })}
    </div>
  );
};

function renderLogo({ user, logo, isLoading }) {
  const logoComponent = (
    <Loadable isLoading={isLoading} size="sm">
      {logo ? <img src={logo} alt="logo" /> : <img className="imperium-logo" src={logoIcon} alt="logo" />}
    </Loadable>
  );

  return shouldShowMenuOptions(user) ? (
    <a className="upper-case title" href="/">
      {logoComponent}
    </a>
  ) : (
    logoComponent
  );
}

Header.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  user: PropTypes.shape(),
  hasFinanceRole: PropTypes.bool,
  rootPath: PropTypes.string.isRequired,
  actions: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  rootPath: getRootPath(state),
  hasFinanceRole: hasRequiredRole('finance')(state),
  hasBuyBondRfqLicence: hasLicences(licences.buyBondRfq)(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    toggleAddTradeSidebar: () => dispatch(toggleAddTradeSidebar()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import './toggle-auto-quoting.scss';

import React, { useState } from 'react';

import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';

import { refreshSession as refreshSessionAction } from '../../actions/session';
import { getSettings, hasLicences } from '../../actions/session-selector';
import { licences } from '../../api/licences';
import { updateAutoQuotingEnabled } from '../../api/ratesheets/update-auto-quoting-limit';
import { MessageType, showToastMessage } from '../toast/toast';

const ToggleAutoQuotingComponent = ({ hasAutoQuotingLicence, refreshSession, isAutoQuotingDisabled }) => {
  const [isAutoQuotingChecked, setAutoQuotingChecked] = useState(!isAutoQuotingDisabled);

  if (!hasAutoQuotingLicence) return null;

  const onChange = async ({ target }) => {
    const { checked } = target;
    try {
      await updateAutoQuotingEnabled(checked);
      await refreshSession();

      setAutoQuotingChecked(checked);
    } catch (err) {
      showToastMessage('There was an error updating the setting', MessageType.ERROR);
    }
  };

  return (
    <div className="auto-quote-toggle-container">
      <FormattedMessage tagName="span" id={isAutoQuotingChecked ? 'autoQuotingOn' : 'autoQuotingOff'} />
      <Toggle checked={isAutoQuotingChecked} icons={false} onChange={onChange} />
    </div>
  );
};

ToggleAutoQuotingComponent.propTypes = {
  hasAutoQuotingLicence: propTypes.bool,
  isAutoQuotingDisabled: propTypes.bool,
};

const mapStateToProps = (state) => {
  const hasAutoQuotingLicence = hasLicences(licences.autoQuoting)(state);
  const tenantSettings = getSettings(state);
  const isAutoQuotingDisabled =
    !hasAutoQuotingLicence || (tenantSettings && tenantSettings.autoQuoteMaxBankAmount === 0);

  return {
    hasAutoQuotingLicence,
    isAutoQuotingDisabled,
  };
};

const mapDispatchToProps = { refreshSession: refreshSessionAction };

export const ToggleAutoQuoting = connect(mapStateToProps, mapDispatchToProps)(ToggleAutoQuotingComponent);

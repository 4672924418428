import './create-rfq.scss';

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Badge, Button, Col, Row, Tab, Table } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change } from 'redux-form';

import { fetchTradesMaturing, fetchTradesSummaryIfNeeded } from '../../actions/holding/holding';
import * as rfqActions from '../../actions/holding/rfq/rfq';
import * as rfqLocalPayloadActions from '../../actions/holding/rfq/rfq-payload';
import * as issuersActions from '../../actions/issuers';
import * as sessionActions from '../../actions/session';
import { interests } from '../../api/holding/codes';
import { getInstrumentCode, TD } from '../../api/holding/instrument-codes';
import {
  ButtonToggle,
  DatePicker,
  GroupedButtons,
  Header,
  Modal,
  PanelPresenter,
  ResultsPresenter,
  Row as FlexRow,
  SkeletonTable,
  SummaryItem,
  Tabs,
  Tooltip,
  ValueHighlight,
} from '../../components/common';
import errorHandler from '../../components/hoc/error-handler';
import includeLocalReducer from '../../components/hoc/include-local-reducer';
import withConfirmDialog from '../../components/hoc/with-confirm-dialog';
import { withPreventNavigation } from '../../components/hoc/with-prevent-navigation';
import { withNavigate, withParams } from '../../components/hoc/with-router-properties';
import addNewRFQForm from '../../components/holding/new-rfq';
import { getPeriodIdAndValues } from '../../components/holding/quotes';
import addRatesheetReinvestmentForm from '../../components/holding/ratesheet-reinvestment';
import { addCdRFQForm, addCpRFQForm, addEcdRFQForm } from '../../components/holding/ratesheets-rfq';
import addRFQReinvestmentForm from '../../components/holding/rfq-reinvestment';
import * as ratesheetsRecordsPresenter from '../../components/holding/rfq/ratesheet-records-presenter';
import { issuerColumns, issuersRecordsPresenter } from '../../components/holding/rfq/records-presenter';
import { SettlementDateModal } from '../../components/holding/rfq/SettlementDateModal';
import config, { env } from '../../config';
import {
  addBusinessDays,
  difference,
  enAuShortDateFormat,
  formatDate,
  formatToShortDate,
  formatToShortDateWithoutTz,
  getPreviousBusinessDate,
  newDate,
  startOfDay,
  today,
  weekdays,
} from '../../date';
import toFloat from '../../parse-float';
import { sortByMaturityCode } from '../../reducers/holding/rfq/quotes';
import rfqLocalPayloadReducer, { customMaturityKey } from '../../reducers/holding/rfq/rfq-payload';
import { routes } from '../../routes';
import { getMoneySymbol, Money } from '../money';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../toast/toast';

const customNewRfqFormValues = {
  instrumentCode: null,
  interestPaidCode: null,
  allocationCode: null,
};

const segmentCodes = {
  FUND: 'FUND',
  BANK: 'BANK',
  CORP: 'CORP',
  LOCALGOV: 'LOCALGOV',
};

const ratesheetTabTypes = {
  ratesheetsTab: 'ratesheetsTab',
  default: 'default',
  demo: 'demo',
};

const tdInstrumentOption = { value: 'td', label: 'TD' };
const cdInstrumentOption = { value: 'ecd', label: 'CD' };
const cpInstrumentOption = { value: 'cp', label: 'CP' };

const buildNewRfqInitialValues = ({ intl }) => ({
  instrumentCode: tdInstrumentOption,
  interestPaidCode: { value: 'z', label: intl.formatMessage({ id: interests.find(({ code }) => code === 'Z').label }) },
  allocationCode: null,
});

const mapStateToProps = (state) => ({
  form: state.form['common-form-validation'],
  holding: state.saveHolding,
  rfq: state.rfq,
  session: state.session,
  segmentCode: state.session.user.tenantSegmentCode,
  issuers: state.issuers,
  tenant: state.tenant,
  licences: state.session.user.licences,
  longTermComplianceOnly: state.holding.summary.longTermComplianceOnly,
  maturing: state.holding.maturing,
  maxRfqIssuerLimit: state.maxRfqIssuerLimit,
  holdingSummary: state.holding.summary,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchSummary: bindActionCreators(fetchTradesSummaryIfNeeded, dispatch),
    rfq: bindActionCreators(rfqActions, dispatch),
    session: bindActionCreators(sessionActions, dispatch),
    issuers: bindActionCreators(issuersActions, dispatch),
    fetchTradesMaturing: bindActionCreators(fetchTradesMaturing, dispatch),
    initializeRfqForm: (data) => {
      Object.keys(data).forEach((field) => dispatch(change('common-form-validation', field, data[field], true)));
    },
  },
});

export class RfqPage extends Component {
  constructor(props) {
    super(props);

    const {
      session: { user },
    } = props;

    this.state = {
      currentMaturityPanel: 'maturities',
      selectedIssuers: [],
      readOnlyIssuers: [],
      bankInitialState: false,
      selectedTabKey: undefined,
      showSettlementModal: false,
      hasShownDealtWithMaturingTrades: false,
      settlementDate: addBusinessDays({
        originalDate: newDate(),
        tPlusDays: user.defaultTPlusNDays,
      }).toDate(),
      tenantSettings: user.tenantSettings,
    };

    this.datePickerOptions = {
      orientation: 'bottom',
      clearBtn: true,
      todayBtn: false,
      startDate: today().add(1, 'days').toDate(),
    };

    this.setBankInitialState(false);

    this.maturityPanels = this.buildMaturityPanels();

    this.newRfqInitialValues = buildNewRfqInitialValues(this.props);

    this.newRfqForm = addNewRFQForm(this.onRequestQuote, this.newRfqInitialValues);
    this.rfqReinvestmentForm = addRFQReinvestmentForm(this.onRequestQuote, customNewRfqFormValues);
    this.ratesheetsRFQForm = addEcdRFQForm(this.onRequestQuote);
    this.ratesheetsCDForm = addCdRFQForm(this.onRequestQuote);
    this.ratesheetsCPForm = addCpRFQForm(this.onRequestQuote);
    this.ratesheetReinvestmentForm = addRatesheetReinvestmentForm(this.onRequestQuote);

    this.handleSelect = this.handleSelect.bind(this);

    this.hasMaturitiesNotDealtWithDialog = {
      titleId: 'hasMaturitiesNotDealtWithTitle',
      contentId: 'hasMaturitiesNotDealtWithContent',
      cancelId: 'hasMaturitiesNotDealtWithCancel',
      confirmId: 'hasMaturitiesNotDealtWithConfirm',
      hasCancel: true,
    };
  }

  componentDidMount() {
    const {
      actions,
      localActions,
      params,
      session: {
        user: { currency },
      },
    } = this.props;

    if (!this.isNewTrade()) {
      actions.rfq.fetchIfNeeded({ id: params.id });
      localActions.updateField('tradeId', params.id);
    }

    actions.rfq.fetchIssuersIfNeeded({ force: this.isNewTrade(), currency });
    actions.rfq.fetchRatesheetsIfNeeded({ force: this.isNewTrade() });
    actions.fetchSummary();
    actions.issuers.fetchIssuersIfNeeded();
    actions.fetchTradesMaturing();

    this.props.onConfirmClick(this.onRequestQuote);
    this.props.setStateCallback(() => this.props.setPreventNavigation(this.isDirty()));
  }

  UNSAFE_componentWillReceiveProps = ({ rfq, maturing, holdingSummary: { commonAllocation } }) => {
    if (this.isNewTrade() && commonAllocation) {
      this.newRfqInitialValues.allocationCode = { value: commonAllocation.code, label: commonAllocation.code };
    }

    if (this.isNewTrade() && this.props.localReducer.tradeId) {
      this.props.localActions.updateField('tradeId', null);
    }

    this.setBankInitialState(true);

    const isLoadingIssuers = !rfq.issuers || !rfq.issuers.list || !rfq.ratesheets || !rfq.ratesheets.list;

    if (!isLoadingIssuers) {
      this.handleMaturitiesToDealWithDialog(maturing);
    }

    this.handleRfqFromOffer(rfq);

    if (this.props.rfq.holding || !rfq.holding) {
      return;
    }

    const currentInterest = interests.find(({ code }) => code === rfq.holding.interestPaidCode);

    customNewRfqFormValues.allocationCode = rfq.holding.allocationCode;
    customNewRfqFormValues.interestPaidCode = {
      value: rfq.holding.interestPaidCode.toLowerCase(),
      label: this.props.intl.formatMessage({ id: currentInterest.label }),
    };
    customNewRfqFormValues.principal = 0;

    this.props.localActions.merge({
      allocationCode: rfq.holding.allocationCode,
      interestPaidCode: rfq.holding.interestPaidCode,
      principal: rfq.holding.principal || 0,
      rfqDate: formatToShortDate(newDate()),
    });
  };

  componentDidUpdate(prevProps) {
    const {
      actions,
      rfq: { isSaved, error, initialValues, issuers },
      session: {
        user: { currency },
      },
    } = this.props;

    if (initialValues && issuers?.list) {
      this.handleRfqInitialValues({ issuers, initialValues });
    }

    if (error && !prevProps.rfq.error) {
      return this.handleError();
    }

    if (isSaved) {
      return this.handleIsSaved(this.props);
    }

    actions.rfq.fetchIssuersIfNeeded({ currency });
    actions.rfq.fetchRatesheetsIfNeeded();
  }

  componentWillUnmount() {
    this.props.actions.rfq.reset();
    this.setState({ readOnlyIssuers: [] });
  }

  handleRfqFromOffer = ({ offerDetails, issuers, ratesheets }) => {
    if (!offerDetails) {
      return;
    }

    if (offerDetails.instrumentCode !== TD) {
      this.handleRfqForDiscountedSecurities({ ratesheets, offerDetails });

      return;
    }

    this.handleRfqForTd({ issuers, offerDetails });
  };

  handleRfqInitialValues = ({ issuers, initialValues }) => {
    const { issuersIds, sourceIssuerId, allocationCode, interestPaid, principal, maturities, additionalFunds } =
      initialValues;

    issuersIds && issuersIds.length && this.selectLocalReducerIssuers({ issuers, sourceIssuerId, issuersIds });

    const currentInterest = interests.find(({ code }) => code === interestPaid);

    maturities?.forEach(({ time, unit }) => this.onMaturityToBeQuotedClick(unit, time));

    const isNewFunds = this.isNewTrade();

    this.props.actions.initializeRfqForm({
      principal: isNewFunds ? principal : additionalFunds,
      allocationCode: isNewFunds
        ? {
            value: allocationCode,
            label: allocationCode,
          }
        : allocationCode,
      interestPaidCode: {
        value: interestPaid.toLowerCase(),
        label: this.props.intl.formatMessage({ id: currentInterest.label }),
      },
    });

    this.props.actions.rfq.setRFQInitialValues({ initialValues: null });
  };

  handleRfqForTd = ({ issuers, offerDetails }) => {
    if (!offerDetails || !issuers || !issuers.list || this.props.rfq.issuers.list) {
      return;
    }

    this.selectLocalReducerIssuers({ issuers, issuersIds: [offerDetails.tradeIssuerId] });
  };

  handleRfqForUnsolicitedRatesheet = ({ issuers, offerDetails }) => {};

  buildInitialFrequentIssuers = ({ issuers, issuersIds, preSelectedIssuers }) => {
    const frequentIssuersPreSelected = [];
    const { localActions, maxRfqIssuerLimit } = this.props;

    issuers?.list?.frequent &&
      issuers.list.frequent
        .filter(({ id }) => issuersIds.includes(id))
        .some((issuer) => {
          localActions.updateIssuers(+issuer.tier, issuer);
          frequentIssuersPreSelected.push(issuer.id);

          if (frequentIssuersPreSelected.length + preSelectedIssuers.length >= maxRfqIssuerLimit) {
            return true;
          }

          return false;
        });

    return frequentIssuersPreSelected;
  };

  selectLocalReducerIssuers = ({ issuers, sourceIssuerId, issuersIds }) => {
    const { maxRfqIssuerLimit } = this.props;

    const preSelectedIssuers = [];

    if (sourceIssuerId) {
      this.selectLocalReducerIssuerById({ issuerId: sourceIssuerId });

      this.includeIssuerInReadOnlyIssuers({ issuersIds: [sourceIssuerId] });

      preSelectedIssuers.push(sourceIssuerId);
    }

    if (preSelectedIssuers.length >= maxRfqIssuerLimit) {
      return;
    }

    const preSelectedFrequentIssuers = this.buildInitialFrequentIssuers({
      issuers,
      issuersIds: issuersIds.filter((issuerId) => !preSelectedIssuers.includes(issuerId)),
      preSelectedIssuers,
    });

    preSelectedIssuers.push(...preSelectedFrequentIssuers);

    if (preSelectedIssuers.length >= maxRfqIssuerLimit) {
      return;
    }

    const restIssuers = issuersIds.filter((issuerId) => !preSelectedIssuers.includes(issuerId));

    restIssuers.some((issuerId) => {
      this.selectLocalReducerIssuerById({ issuerId });
      preSelectedIssuers.push(issuerId);

      return preSelectedIssuers.length >= maxRfqIssuerLimit;
    });
  };

  selectLocalReducerIssuerById = ({ issuerId }) => {
    const {
      localActions,
      rfq: { issuers },
    } = this.props;

    let issuerToSelect;

    Object.keys(issuers.list).some((issuerTierType) => {
      const issuersByTier = issuers.list[issuerTierType];
      const issuer = issuersByTier.find(({ id }) => id === issuerId);

      if (!issuer) {
        return false;
      }

      issuerToSelect = {
        issuer,
        tier: +issuerTierType,
      };

      return true;
    });

    if (!issuerToSelect) {
      return;
    }

    localActions.updateIssuers(issuerToSelect.tier, issuerToSelect.issuer);
  };

  handleRfqForDiscountedSecurities = ({ ratesheets, offerDetails }) => {
    if (!ratesheets || !ratesheets.list || !ratesheets.list.length || this.props.rfq.ratesheets.list) {
      return;
    }

    const ratesheetByInstrument = ratesheets.list.find(
      (ratesheetByInstrumentCode) =>
        ratesheetByInstrumentCode.instrumentCode === getInstrumentCode(offerDetails.instrumentCode),
    );

    const preSelectedIssuer = ratesheetByInstrument.ratesheet
      .filter((ratesheetItem) => ratesheetItem.issuer.currencies.includes(this.props.session.user.currency))
      .find(({ issuer }) => issuer.id === offerDetails.tradeIssuerId);

    if (!preSelectedIssuer) {
      return;
    }

    const { localActions } = this.props;

    localActions.updateRatesheetIssuers(ratesheetTabTypes.ratesheetsTab, {
      ...preSelectedIssuer.issuer,
      type: preSelectedIssuer.isDemo ? ratesheetTabTypes.demo : ratesheetTabTypes.default,
    });

    this.includeIssuerInReadOnlyIssuers({ issuersIds: [offerDetails.tradeIssuerId] });
  };

  includeIssuerInReadOnlyIssuers = ({ issuersIds }) =>
    this.setState((prevState) => ({
      readOnlyIssuers: [...prevState.readOnlyIssuers, ...issuersIds],
    }));

  onSettlementDateSelected = (settlementDate) =>
    this.setState({ settlementDate, showSettlementModal: false }, this.clearMaturities);

  onSettlementDateCancel = () => this.setState({ showSettlementModal: false });

  handleSettlementDate = () => this.setState({ showSettlementModal: true });

  handleMaturitiesToDealWithDialog(nextMaturing) {
    if (
      !this.isNewTrade() ||
      this.state.hasShownDealtWithMaturingTrades ||
      nextMaturing.isFetching ||
      nextMaturing.notDealtWith === 0
    ) {
      return;
    }

    const { toggleConfirmDialog, onConfirmClick, navigate } = this.props;

    toggleConfirmDialog({
      ...this.hasMaturitiesNotDealtWithDialog,
      contentValues: { notDealtWith: nextMaturing.notDealtWith },
    });

    onConfirmClick(() => {
      toggleConfirmDialog();
      navigate(routes.portfolioRoot.rfqs);
    });

    this.setState({ hasShownDealtWithMaturingTrades: true });
  }

  onInterestPaidChange(name, value) {
    this.props.localActions.updateField(name, value);
  }

  onPrincipalChange(event, value) {
    const { localActions, rfq } = this.props;
    const additionalFunds = Number(value);

    localActions.merge({
      additionalFunds,
      principal: additionalFunds + rfq.holding.principal,
    });
  }

  onIssuerSelectChange(tier, issuer) {
    const { localActions } = this.props;

    !this.isEcd() && !this.isCp() && this.handleComplianceBreach(issuer);

    localActions.updateIssuers(issuer.tier || tier, issuer);
  }

  onRatesheetIssuerSelectChange(tier, type, issuer) {
    const { localActions } = this.props;

    localActions.updateRatesheetIssuers(tier, { ...issuer, type });
  }

  onMaturityToBeQuotedClick = (key, value) =>
    this.props.localActions.updateMaturity(key, value, this.state.settlementDate);

  onCustomizeMaturityDateChange = (code) => (date) => {
    const { customizedMaturities } = this.state;

    this.setState({
      customizedMaturities: {
        ...customizedMaturities,
        [code]: date,
      },
    });
  };

  onRequestClick = () => {
    const { toggleConfirmDialog, onConfirmClick, rfq, form } = this.props;
    const issuersWarnings = !this.isEcd() && !this.isCp() && this.getIssuersWarnings();
    const dialogOptions = { contentId: 'rfqConfirm', titleId: 'defaultConfirmTitle' };

    if (issuersWarnings.length > 0) {
      const issuersNames = issuersWarnings
        .map((issuer) =>
          buildIssuerComplianceBreachMessage({
            compliances: issuer.minimalAmountAvailable,
            issuerName: issuer.shortName,
            rawPrincipal: form.values.principal,
          }),
        )
        .sort()
        .join('')
        .trim();

      dialogOptions.contentId = rfq.issuers.hasCompliances
        ? 'rfqRequestWarningConfirmation'
        : 'rfqRequestNoCompliancesWarningConfirmation';

      dialogOptions.contentValues = { issuersNames };
    }

    onConfirmClick(this.onRequestQuote);
    toggleConfirmDialog(dialogOptions);
  };

  onRequestQuote = () => {
    const { settlementDate } = this.state;
    const {
      actions,
      localReducer,
      form,
      rfq: { holding: reinvestmentTrade, offerDetails },
      session: { user },
      toggleConfirmDialog,
      maxRfqIssuerLimit,
    } = this.props;

    const isNewTrade = this.isNewTrade();

    if (!isNewTrade && localReducer.principal < reinvestmentTrade.principal) {
      showToastMessage(this.props.intl.formatMessage({ id: 'rfqPrincipalSmaller' }));
      toggleConfirmDialog();

      return;
    }

    if (localReducer.issuers.length + localReducer.ratesheetIssuers.length > maxRfqIssuerLimit) {
      showToastMessage(this.props.intl.formatMessage({ id: 'rfqMinimumIssuersSelected' }, { maxRfqIssuerLimit }));
      toggleConfirmDialog();

      return;
    }

    const { values } = form || {};
    const isDiscountedSecurity = this.isEcd() || this.isCp();

    const { issuers, ratesheetIssuers } = localReducer;
    const formInstrumentCode = this.getTradeSettingsFormValue('instrumentCode');
    const formPrincipalValue = toFloat(values.principal) || 0;
    const issuerIds = isDiscountedSecurity ? ratesheetIssuers.map((issuer) => issuer.id) : issuers;
    let reinvestmentPrincipal = 0;
    if (reinvestmentTrade) {
      reinvestmentPrincipal = reinvestmentTrade.notionalPrincipal || reinvestmentTrade.principal;
    }

    const rfqPayload = {
      allocationCode: this.getTradeSettingsFormValue('allocationCode'),
      instrumentCode: isNewTrade ? formInstrumentCode.toUpperCase() : reinvestmentTrade.instrumentCode,
      interestPaidCode: isDiscountedSecurity ? 'z' : this.getTradeSettingsFormValue('interestPaidCode'),
      maturities: localReducer.maturities.map(({ maturityCode, originalDate, date }) => ({
        maturityCode,
        date: formatToShortDate(date),
        originalDate: originalDate && formatToShortDate(originalDate),
      })),
      issuers: issuerIds,
      principal: formPrincipalValue + (isNewTrade ? 0 : reinvestmentPrincipal),
      rfqDate: formatToShortDate(newDate()),
      tradeId: localReducer.tradeId,
    };

    if (user.defaultTPlusNDays > 0) {
      rfqPayload.settlementDate = formatToShortDate(settlementDate);
    }

    if (offerDetails) {
      rfqPayload.offerId = offerDetails.id;
    }

    const tenants = this.props.issuers.data
      .filter((issuer) => issuerIds.includes(issuer.id))
      .map((issuer) => issuer.domain);

    actions.rfq.reinvest(rfqPayload, tenants);

    this.props.setPreventNavigation(false);

    this.props.updateConfirmationProperties({
      disabled: true,
      isConfirming: true,
    });
  };

  onDateChange = (date) => {
    this.onMaturityToBeQuotedClick(customMaturityKey, date);
  };

  onMaturitiesToggleClick = (currentMaturityPanel) => {
    this.setState({ currentMaturityPanel });
    this.clearMaturities();
  };

  onSortColumnOrder = ({ sort }) => {
    this.props.actions.rfq.updateSortColumn({ sort });
  };

  onCloseCustomizeDates = () =>
    this.setState({
      showDialog: false,
      customizedMaturities: null,
    });

  onCustomizedMaturityDatesConfirm = () => {
    const { customizedMaturities } = this.state;
    customizedMaturities && this.props.localActions.customizeMaturities(customizedMaturities);
    this.onCloseCustomizeDates();
  };

  getAvailableInstrumentCodes() {
    const {
      segmentCode,
      session: { user },
    } = this.props;

    if (user.isBank) {
      return [cpInstrumentOption, cdInstrumentOption, tdInstrumentOption];
    }

    switch (segmentCode) {
      case segmentCodes.BANK:
        return [cpInstrumentOption, cdInstrumentOption, tdInstrumentOption];
      case segmentCodes.LOCALGOV:
      case segmentCodes.FUND:
      case segmentCodes.CORP:
      default:
        return [tdInstrumentOption, cdInstrumentOption];
    }
  }

  getIssuersWarnings() {
    return this.state.selectedIssuers.filter((issuer) => {
      const breachComplianceInfo = this.getComplianceBreachInfo(issuer);

      return breachComplianceInfo.canBreachCompliance;
    });
  }

  getCustomMaturityDate() {
    const maturity = this.props.localReducer.maturities.find(({ maturityCode }) => maturityCode === customMaturityKey);

    return maturity ? maturity.date : null;
  }

  getAdisSelected = () => this.props.localReducer.issuers.length;

  getButtonsToBeQuoted(length, titleId, key, startCount, numbersByRow) {
    const isCustomMaturityDateFilled = this.getCustomMaturityDate();
    const isTenorButtonActive = (number) =>
      this.props.localReducer.maturities.map(({ maturityCode }) => maturityCode).includes(`${number}${key}`);

    return (
      <div className="flex-column">
        <label>
          <FormattedMessage id={titleId} />
        </label>
        <div className="maturity-buttons" data-toggle={!isCustomMaturityDateFilled ? 'buttons' : ''}>
          <GroupedButtons
            className={classnames('btn btn-default', {
              disabled: isCustomMaturityDateFilled,
            })}
            isActive={isTenorButtonActive}
            containerClassName="flex-container"
            length={length}
            numbersByRow={numbersByRow}
            onClick={(number) => this.onMaturityToBeQuotedClick(key, number)}
            startCount={startCount}
          />
        </div>
      </div>
    );
  }

  getHeaderTitle(holding) {
    return !this.isNewTrade() && holding ? `${holding.instrumentCode.toLowerCase()}Title` : 'newRfqTitle';
  }

  getMinimalAmountByTerm(minimalAmountAvailable) {
    const longResult = {
      minimalAmountAvailable: minimalAmountAvailable.long,
      dialogMessageContentId: 'rfqBreachLongTermCompliance',
    };

    const shortResult = {
      minimalAmountAvailable: minimalAmountAvailable.short,
      dialogMessageContentId: 'rfqBreachShortTermCompliance',
    };

    const shortAndLongResult = {
      minimalAmountAvailable: Math.min(minimalAmountAvailable.short, minimalAmountAvailable.long),
      dialogMessageContentId: 'rfqBreachCompliance',
    };

    if (this.props.longTermComplianceOnly) {
      return longResult;
    }

    const maturityDates = this.props.localReducer.maturities.map(({ date }) => date);

    if (!maturityDates.length) return shortAndLongResult;

    const hasShortTerm = maturityDates.some(this.isShortTerm);
    const hasLongTerm = maturityDates.some(this.isLongTerm);

    if (hasShortTerm && hasLongTerm) return shortAndLongResult;

    return hasShortTerm ? shortResult : longResult;
  }

  getComplianceBreachInfo(issuer) {
    const { localReducer, form, rfq } = this.props;

    if (!rfq.issuers.hasCompliances) {
      return {
        canBreachCompliance: true,
        dialogMessageContentId: 'rfqNoCompliancesLoaded',
      };
    }

    const formPrincipal = (form && form.values && toFloat(form.values.principal)) || 0;
    const totalRfqPrincipal = this.isNewTrade() ? formPrincipal : formPrincipal + localReducer.principal;
    const minimalAmountByTerm = this.getMinimalAmountByTerm(issuer.minimalAmountAvailable);
    const rolloverAmount = this.isRollover({ issuer, holding: rfq.holding }) ? localReducer.principal : 0;
    const isRfqAmountCompliant = minimalAmountByTerm.minimalAmountAvailable + rolloverAmount < totalRfqPrincipal;

    return {
      canBreachCompliance: !issuer.ignoreCompliances && isRfqAmountCompliant,
      dialogMessageContentId: minimalAmountByTerm.dialogMessageContentId,
    };
  }

  getTradeSettingsFormValue(property) {
    const formProperty = this.props.form && this.props.form.values && this.props.form.values[property];

    return formProperty && (formProperty.value || formProperty);
  }

  getCurrentInstrumentCode() {
    const reinvestmentInstrumentCode = (this.props.rfq.holding && this.props.rfq.holding.instrumentCode) || '';
    const formInstrumentCode = this.getTradeSettingsFormValue('instrumentCode') || '';
    const currentInstrumentCode = this.isNewTrade() ? formInstrumentCode : reinvestmentInstrumentCode;

    return currentInstrumentCode.toLowerCase();
  }

  setBankInitialState(enableSetState) {
    const isBank = this.props.segmentCode === 'BANK' || this.props.session.user.isBank;

    if (this.isNewTrade() && !this.state.bankInitialState && isBank) {
      this.updateSelectedInstrumentCode('ecd');
      enableSetState && this.setState({ bankInitialState: true });
    }
  }

  getIssuerPanes(filter, issuersState) {
    const { isFetching, list: issuersByTierType } = issuersState;
    const highlightedColumn = (filter && filter.sort) || rfqActions.DEFAULT_SORT_COLUMN;
    const { issuers: selectedIssuers } = this.props.localReducer;

    const {
      rfq,
      session: {
        user: { currency },
      },
    } = this.props;

    const tdInstrument =
      rfq.ratesheets.list && rfq.ratesheets.list.find((ratesheet) => ratesheet.instrumentCode === 'TD');
    const tdRatesheets = tdInstrument ? tdInstrument.ratesheet : [];

    const currencySymbol = getMoneySymbol({ currency, short: true });
    const columns = issuerColumns(currencySymbol);

    if (this.props.longTermComplianceOnly) {
      delete columns.short;
      delete columns.shortAmountAvailable;
    }

    const issuersPanes = Object.keys(issuersByTierType).map((issuerTierType) => {
      const issuers = issuersByTierType[issuerTierType]
        .filter((issuer) => issuer.frontOfficeEmail && issuer.frontOfficeEmail !== 'administrator@imperium.markets')
        .map((issuer) => {
          if (selectedIssuers && selectedIssuers.includes(issuer.id)) {
            return { ...issuer, selected: true };
          }

          return { ...issuer, selected: false };
        });

      const payload = {
        isFetching,
        noRecords: !issuers || !issuers.length,
        recordsPresenter: {
          data: issuers,
          presenter: issuersRecordsPresenter,
          columns,
          onSortColumnOrder: this.onSortColumnOrder,
          highlightedColumn,
          actions: {
            onIssuerSelectChange: this.onIssuerSelectChange.bind(this, issuerTierType),
          },
          options: {
            tenantIssuerId: this.props.session.user.tenantIssuerId,
            longTermComplianceOnly: this.props.longTermComplianceOnly,
            tdRatesheets,
            readOnlyIssuers: this.state.readOnlyIssuers,
            reinvestmentPrincipal: this.isNewTrade() ? 0 : rfq.holding.principal,
            reinvestmentIssuerId: this.isNewTrade() ? 0 : rfq.holding.issuerId,
          },
        },
      };

      return (
        <Tab.Pane key={issuerTierType} eventKey={issuerTierType}>
          <ResultsPresenter {...payload} />
        </Tab.Pane>
      );
    });

    return issuersPanes;
  }

  updateSelectedInstrumentCode(customValue) {
    const instrumentCode = this.getTradeSettingsFormValue('instrumentCode');

    if (customValue || instrumentCode) {
      customNewRfqFormValues.instrumentCode = customValue || instrumentCode;
    }
  }

  isEcd() {
    const currentInstrumentCode = this.getCurrentInstrumentCode();

    return currentInstrumentCode ? currentInstrumentCode.toLowerCase() === 'ecd' : false;
  }

  isCp() {
    const currentInstrumentCode = this.getCurrentInstrumentCode();

    return currentInstrumentCode ? currentInstrumentCode.toLowerCase() === 'cp' : false;
  }

  handleComplianceBreach(issuer) {
    const { onConfirmClick, toggleConfirmDialog } = this.props;
    const breachComplianceInfo = this.getComplianceBreachInfo(issuer);
    const isIssuerSelected = this.state.selectedIssuers.some((selectedIssuer) => selectedIssuer.id === issuer.id);
    isIssuerSelected ? this.removeSelectedIssuer(issuer) : this.addSelectedIssuer(issuer);

    if (!isIssuerSelected && breachComplianceInfo.canBreachCompliance) {
      toggleConfirmDialog({
        contentId: breachComplianceInfo.dialogMessageContentId,
        confirmId: 'ok',
        titleId: 'rfqAmountAvailableSmallerTitle',
        hasCancel: false,
      });
    }

    onConfirmClick(() => {
      toggleConfirmDialog({
        contentId: breachComplianceInfo.dialogMessageContentId,
        hasCancel: true,
      });
    });
  }

  removeSelectedIssuer(issuer) {
    const issuerIndex = this.state.selectedIssuers.findIndex((selectedIssuer) => selectedIssuer.id === issuer.id);

    if (issuerIndex > -1) {
      const selectedIssuers = Array.from(this.state.selectedIssuers);
      selectedIssuers.splice(issuerIndex, 1);
      this.setState({
        selectedIssuers,
      });
    }
  }

  addSelectedIssuer(issuer) {
    const selectedIssuers = Array.from(this.state.selectedIssuers);
    selectedIssuers.push(issuer);
    this.setState({
      selectedIssuers,
    });
  }

  isLongTerm = (maturityDate) => {
    const startOfDayToday = startOfDay();

    return difference(maturityDate, startOfDayToday, 'days', false) >= 365;
  };

  isShortTerm = (maturityDate) => !this.isLongTerm(maturityDate);

  isNewTrade() {
    const { params } = this.props;

    return params && params.id === 'new';
  }

  isRollover({ issuer, holding }) {
    const rolloverIssuerId = holding && holding.issuerId;

    return issuer.id === rolloverIssuerId;
  }

  toggleDialog() {
    const { showDialog } = this.state;

    this.setState({ showDialog: !showDialog });
  }

  clearMaturities() {
    this.props.localActions.cleanMaturityToBeQuoted();
  }

  buildMaturityPanels() {
    const items = [
      {
        title: 'rfqMaturities',
        value: 'maturities',
      },
      {
        title: 'rfqDate',
        value: 'date',
      },
    ];

    return {
      items,
      date: this.renderDateToBeQuoted,
      maturities: this.renderMaturitiesToBeQuoted,
    };
  }

  handleError = () => {
    const {
      rfq: { error },
      intl,
      setPreventNavigation,
      toggleConfirmDialog,
    } = this.props;

    toggleConfirmDialog({
      isConfirming: false,
      disabled: false,
    });

    setPreventNavigation(this.isDirty());

    showResponseErrorMessage({ intl, error });
  };

  handleIsSaved({ actions, localActions, navigate }) {
    actions.rfq.resetSavingState();
    localActions.reset();

    navigate(this.isNewTrade() ? '/' : routes.portfolioRoot.rfqs);

    showToastMessage(this.props.intl.formatMessage({ id: 'rfqSavedSuccessfully' }), MessageType.SUCCESS);
  }

  isDirty() {
    const {
      rfq: { holding },
      localReducer,
    } = this.props;
    const { interestPaidCode, issuers, maturities, principal } = localReducer;

    return (
      issuers.length ||
      maturities.length ||
      (holding && (interestPaidCode !== holding.interestPaidCode || principal !== holding.principal))
    );
  }

  handleSelect(selectedTabKey) {
    this.setState({ selectedTabKey });
  }

  isRfqRequestButtonDisabled = ({ isFetching }) => {
    const {
      localReducer: { isValid, ratesheetIssuers },
      form,
    } = this.props;
    const { settlementDate } = this.state;

    const formHasErrors =
      !form ||
      (this.isNewTrade() && !form.anyTouched) ||
      (form.anyTouched && form.asyncErrors && !!Object.keys(form.asyncErrors).length);

    const isDiscountedSecurity = this.isEcd() || this.isCp();
    const isValidDiscountedSecurity = isDiscountedSecurity ? ratesheetIssuers && ratesheetIssuers.length : true;

    return !settlementDate || isFetching || !isValid || !isValidDiscountedSecurity || formHasErrors;
  };

  renderHeaderSummary({ holding, isFetching }) {
    const shouldShowSummary = !this.isNewTrade() && holding && holding.principal > 0;
    const principal = holding && (holding.notionalPrincipal || holding.principal);

    return (
      <Header titleId={this.getHeaderTitle(holding)}>
        <FlexRow className="summary" alignItemsCenter>
          <SummaryItem top={<FormattedMessage id="currency" />} middle={<Money long />} />
          {shouldShowSummary && (
            <SummaryItem
              top={<FormattedMessage id="amountReinvested" />}
              middle={this.renderTotalToBeReinvested(principal)}
              bottom={
                <div className="amount-total text-ellipsis">
                  <FormattedMessage tagName="span" id="amountMaturing" />
                  <Money value={principal} short />
                </div>
              }
              isFetching={isFetching}
            />
          )}
        </FlexRow>
      </Header>
    );
  }

  renderTotalToBeReinvested = (principal) => {
    const { localReducer, form } = this.props;
    const formPrincipal = (form && form.values && toFloat(form.values.principal)) || 0;
    const value = (principal || localReducer.principal) + formPrincipal;
    const totalInCurrency = <Money value={value} short />;

    return (
      <Tooltip id="totalReinvested" placement="bottom" tooltipComponent={totalInCurrency}>
        {totalInCurrency}
      </Tooltip>
    );
  };

  renderDateToBeQuoted = () => {
    const date = this.getCustomMaturityDate();

    return (
      <div className="flex-container">
        <div className="flex-column">
          <label>
            <FormattedMessage id="rfqCustomDate" />
          </label>
          <DatePicker
            options={this.datePickerOptions}
            onChange={this.onDateChange}
            value={date}
            disableWeekends
            disableHolidays
          />
        </div>
      </div>
    );
  };

  renderMaturitiesToBeQuoted = () => (
    <div className="flex-container">
      {this.renderMonthsToBeQuoted()}
      {this.renderYearsToBeQuoted()}
      {this.renderCustomizeMaturityDatesButton()}
    </div>
  );

  renderMonthsToBeQuoted() {
    const { tenantSettings } = this.state;
    return this.getButtonsToBeQuoted(tenantSettings?.shouldShowUpToTwoYearTenors ? 23 : 12, 'rfqMonths', 'M', 1, 3);
  }

  renderYearsToBeQuoted() {
    return this.getButtonsToBeQuoted(4, 'rfqYears', 'Y', 2, 4);
  }

  renderTradeSettingsForm(isLoadingIssuers) {
    const {
      newRfqForm,
      rfqReinvestmentForm,
      ratesheetsRFQForm,
      ratesheetsCDForm,
      ratesheetsCPForm,
      ratesheetReinvestmentForm,
      props: {
        segmentCode,
        holding,
        rfq: { isFetching },
        session: { user },
      },
    } = this;

    let currentForm;

    if (this.isNewTrade()) {
      customNewRfqFormValues.allocationCode = '';
      customNewRfqFormValues.interestPaidCode = '';
      customNewRfqFormValues.principal = '';
    }

    const currentInstrumentCode = this.getCurrentInstrumentCode();
    currentInstrumentCode && this.updateSelectedInstrumentCode(currentInstrumentCode);

    if (this.isCp()) {
      currentForm = this.isNewTrade() ? ratesheetsCPForm : ratesheetReinvestmentForm;
    } else if (this.isEcd()) {
      const useCdForm = [segmentCodes.FUND, segmentCodes.CORP].includes(segmentCode);
      const ratesheetsForm = useCdForm ? ratesheetsCDForm : ratesheetsRFQForm;

      currentForm = this.isNewTrade() ? ratesheetsForm : ratesheetReinvestmentForm;
    } else {
      currentForm = this.isNewTrade() ? newRfqForm : rfqReinvestmentForm;
    }

    this.updateSelectedInstrumentCode();

    const formData = {
      ...holding,
      availableInstrumentCodes: this.getAvailableInstrumentCodes(),
    };
    const buttonDisabled = this.isRfqRequestButtonDisabled({ isFetching });

    const { form: Form } = currentForm;

    return (
      <SkeletonTable isLoading={isLoadingIssuers} rows={1}>
        <PanelPresenter className="trade-settings-panel">
          <FlexRow contentBetween wrap flex={1}>
            {Form && (
              <FlexRow>
                <Form
                  cancelLabel="backToHoldings"
                  onSubmit={this.onRequestQuote}
                  shouldResetForm={holding && holding.isSaved}
                  data={formData}
                  customClass={currentForm.className}
                  user={user}
                  hideActions
                />
                {user.defaultTPlusNDays > 0 && this.renderSettlementDate()}
              </FlexRow>
            )}
            <Button disabled={buttonDisabled} className="btn btn-solid-primary" onClick={this.onRequestClick}>
              <FormattedMessage id="rfqRequest" />
            </Button>
          </FlexRow>
        </PanelPresenter>
      </SkeletonTable>
    );
  }

  renderSettlementDate = () => {
    const { settlementDate, showSettlementModal } = this.state;

    return (
      <React.Fragment>
        <div className="settlement-date-wrapper">
          <p className="white-color text-ellipsis">
            <FormattedMessage id="settlementDate" />
          </p>
          <div className="settlement-date" onClick={this.handleSettlementDate} role="presentation">
            <input type="text" value={formatDate(settlementDate, enAuShortDateFormat)} disabled="disabled" />
            <i className="icon-calendario" />
          </div>
        </div>
        <SettlementDateModal
          show={showSettlementModal}
          onConfirm={this.onSettlementDateSelected}
          date={settlementDate}
          onCancel={this.onSettlementDateCancel}
        />
      </React.Fragment>
    );
  };

  renderMaturities(isLoadingIssuers) {
    const { currentMaturityPanel } = this.state;
    const { items } = this.maturityPanels;

    return (
      <SkeletonTable isLoading={isLoadingIssuers} rows={1}>
        <PanelPresenter className="rfq-maturities-panel">
          <div className="flex-container">
            <ButtonToggle active={currentMaturityPanel} items={items} onClick={this.onMaturitiesToggleClick} />
            {this.maturityPanels[currentMaturityPanel]()}
          </div>
        </PanelPresenter>
      </SkeletonTable>
    );
  }

  renderCustomizeMaturityDatesButton() {
    const { maturities } = this.props.localReducer;

    return (
      <div className="customize-button">
        <button
          type="button"
          disabled={!maturities.length}
          className="btn btn-accent"
          onClick={() => this.toggleDialog()}
        >
          <FormattedMessage id="rfqCustomizeDates" />
        </button>
        {maturities.length > 0 && (
          <Modal
            cancelId="cancel"
            confirmId="confirm"
            confirmDisabled={!this.state.customizedMaturities}
            dialogClassName="customize-date-modal"
            show={this.state.showDialog}
            onCancel={this.onCloseCustomizeDates}
            onConfirm={this.onCustomizedMaturityDatesConfirm}
            titleId="rfqCustomizeDates"
          >
            {this.renderSetMaturitiesPanels()}
          </Modal>
        )}
      </div>
    );
  }

  renderSetMaturitiesPanels() {
    const { rfqDate, maturities } = this.props.localReducer;

    const setMaturityRows = maturities
      .map(({ maturityCode, date, originalDate }) => ({
        code: maturityCode,
        originalDate,
        date,
      }))
      .sort(sortByMaturityCode)
      .map(({ code, date, originalDate }) => {
        const periodLabel = getPeriodIdAndValues(code);

        if (!periodLabel) {
          return null;
        }

        const { customizedMaturities } = this.state;
        const tenorMaturityDate = (customizedMaturities && customizedMaturities[code]) || date;
        const differenceDays = {
          days: difference(tenorMaturityDate, !rfqDate ? formatToShortDateWithoutTz(newDate()) : rfqDate),
        };

        const originalTenorMaturityDate = originalDate || date;
        const includeDateIntervals = [
          {
            start: getPreviousBusinessDate({
              date: originalTenorMaturityDate,
              tMinusDays: config.env.rfqReviewDatesRange,
            }).toDate(),
            end: addBusinessDays({
              date: originalTenorMaturityDate,
              tPlusDays: config.env.rfqReviewDatesRange,
            }).toDate(),
          },
        ];

        return (
          periodLabel && (
            <tr
              key={code}
              className={classnames({
                'is-custom': (customizedMaturities && customizedMaturities[code]) || originalDate,
              })}
            >
              <td>
                <FormattedMessage id={periodLabel.id} values={periodLabel.values} />
              </td>
              <td className="customized-date">
                <DatePicker
                  borderBottomOnly
                  onChange={this.onCustomizeMaturityDateChange(code)}
                  value={tenorMaturityDate && tenorMaturityDate.toString()}
                  includeDateIntervals={includeDateIntervals}
                  portalId="imperium-datepicker-portal"
                  disableWeekends
                  disableHolidays
                />
              </td>
              <td>
                <span>{weekdays[newDate(tenorMaturityDate).weekday()]} </span>(
                <FormattedMessage id="inDays" values={differenceDays} />)
              </td>
            </tr>
          )
        );
      });

    return (
      setMaturityRows.length > 0 && (
        <Table responsive striped className="middle customize-dates-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="holdingMaturityDate" />
              </th>
              <th>
                <FormattedMessage id="rfqDate" />
              </th>
              <th>
                <FormattedMessage id="difference" />
              </th>
            </tr>
          </thead>
          <tbody>{setMaturityRows}</tbody>
        </Table>
      )
    );
  }

  renderTabContainer = (rfq, isLoadingIssuers) => {
    const { tiers } = rfqActions;

    const isEcd = this.isEcd();
    const isCp = this.isCp();
    const availableTdTiersTabs = rfq.issuers && rfq.issuers.list && Object.keys(rfq.issuers.list);
    const hasFrequentIssuers = availableTdTiersTabs && rfq.issuers.list.frequent;
    const termDepositDefaultActiveKey = hasFrequentIssuers
      ? tiers.frequent
      : availableTdTiersTabs && availableTdTiersTabs[0];

    const defaultActiveKey = isEcd || isCp ? '1' : termDepositDefaultActiveKey;

    let isValidTabKey;

    if (isEcd || isCp) {
      isValidTabKey = this.state.selectedTabKey && this.state.selectedTabKey <= 2;
    } else {
      isValidTabKey = availableTdTiersTabs && availableTdTiersTabs.includes(this.state.selectedTabKey);
    }

    const activeKey = isValidTabKey ? this.state.selectedTabKey : defaultActiveKey;

    return (
      <SkeletonTable isLoading={isLoadingIssuers}>
        <Tab.Container
          id="tabs-with-dropdown"
          onSelect={this.handleSelect}
          defaultActiveKey={defaultActiveKey}
          activeKey={activeKey}
        >
          <div>
            {this.renderTabItems(rfq)}
            {this.renderIssuersPanel(rfq)}
          </div>
        </Tab.Container>
      </SkeletonTable>
    );
  };

  renderTabItems = ({ issuers: issuersState }) => {
    const {
      rfq,
      session: {
        user: { currency },
      },
    } = this.props;

    const hasDemoIssuer = rfq.issuers && rfq.issuers.list && rfq.issuers.list[4] && !!rfq.issuers.list[4].length;

    const cdRatesheet =
      rfq.ratesheets &&
      rfq.ratesheets.list &&
      rfq.ratesheets.list.length &&
      rfq.ratesheets.list.find((instrument) => instrument.instrumentCode === 'CD');

    const hasDemoRatesheet =
      cdRatesheet &&
      !!cdRatesheet.ratesheet.find(
        (ratesheetItem) => ratesheetItem.isDemo && ratesheetItem.issuer.currencies.includes(currency),
      );

    const tabItems =
      this.isEcd() || this.isCp()
        ? this.renderRatesheetsTabs(hasDemoRatesheet)
        : this.renderLocalGovTabs(issuersState, hasDemoIssuer);

    return <Tabs items={tabItems} />;
  };

  renderRatesheetsTabs = (hasDemoRatesheet) => {
    const ratesheetsTabs = [];
    const { ratesheetIssuers } = this.props.localReducer;

    const defaultCount = ratesheetIssuers.filter((issuer) => !issuer.isDemo).length;
    const demoCount = ratesheetIssuers.filter((issuer) => issuer.isDemo).length;

    const getTabComponent = (key, title, counter) => ({
      key,
      href: key,
      component: (
        <div>
          <FormattedMessage tagName="span" id={title} />
          {counter > 0 ? <Badge className="medium-blue default-border-radius">{counter}</Badge> : null}
        </div>
      ),
    });

    const defaultRatesheetsTab = getTabComponent('1', 'ecdIndicativeRates', defaultCount);
    const demoRatesheetsTab = getTabComponent('2', 'rfqDemoIssuers', demoCount);

    ratesheetsTabs.push(defaultRatesheetsTab);
    hasDemoRatesheet && ratesheetsTabs.push(demoRatesheetsTab);

    return ratesheetsTabs;
  };

  renderLocalGovTabs = (issuersState, hasDemoIssuer) => {
    if (!issuersState || !issuersState.list) {
      return [];
    }

    const { list: issuersByTierType } = issuersState;
    const { tiers } = rfqActions;

    const demoTierType = 5;

    const tabs = Object.keys(issuersByTierType)
      .filter((tierType) => hasDemoIssuer || (!hasDemoIssuer && tierType !== demoTierType))
      .map((issuerTierType) => ({
        key: issuerTierType,
        href: issuerTierType,
        component: this.buildTierTabItem(issuerTierType, tiers[issuerTierType]),
      }));

    if (issuersByTierType.frequent) {
      const [frequentTabItem] = tabs.splice(
        tabs.findIndex(({ key }) => key === tiers.frequent),
        1,
      );

      tabs.unshift(frequentTabItem);
    }

    tabs.push({
      key: 'adis-selected-tab',
      href: 'adis-selected-tab',
      isCleanItem: true,
      component: (
        <ValueHighlight className="pull-right" label="rfqAdisSelected">
          {this.getAdisSelected()} of {env.maxRfqIssuerLimit}
        </ValueHighlight>
      ),
    });

    return tabs;
  };

  buildTierTabItem(issuerTierType, title) {
    const { tiers } = this.props.localReducer;
    const selectedIssuersCount = tiers[issuerTierType];

    return (
      <div>
        <FormattedMessage id={title} />
        {selectedIssuersCount > 0 ? (
          <Badge className="medium-blue default-border-radius">{tiers[issuerTierType]}</Badge>
        ) : null}
      </div>
    );
  }

  renderIssuersPanel = ({ filter, issuers: issuersState, ratesheets }) => {
    const isDiscountedSecurity = this.isEcd() || this.isCp();
    const isLoadingIssuers = !issuersState || !issuersState.list;
    const isLoadingRatesheets = isDiscountedSecurity && (!ratesheets || !ratesheets.list);

    const panes = isDiscountedSecurity
      ? !isLoadingRatesheets && this.getRatesheetPanes(filter, ratesheets)
      : !isLoadingIssuers && this.getIssuerPanes(filter, issuersState);

    return (
      <PanelPresenter className="issuers-panel">
        <Tab.Content animation>{panes}</Tab.Content>
      </PanelPresenter>
    );
  };

  getRatesheetPanes(filter, ratesheets) {
    const highlightedColumn = (filter && filter.sort) || rfqActions.DEFAULT_SORT_COLUMN;
    const { ratesheetIssuers: selectedIssuers } = this.props.localReducer;

    const rawInstrumentCode = this.getCurrentInstrumentCode().toUpperCase();
    const instrumentCode = rawInstrumentCode === 'ECD' ? 'CD' : rawInstrumentCode;

    const ratesheetByInstrument = ratesheets.list.find(
      (ratesheetByInstrumentCode) => ratesheetByInstrumentCode.instrumentCode === instrumentCode,
    );

    if (!ratesheetByInstrument) {
      const results = [...Array(2).keys()].map((value) => (
        <Tab.Pane key={value + 1} eventKey={`${value + 1}`}>
          <Row>
            <Col xs={12} className="text-center">
              <FormattedMessage id="unavailableRatesheet" />
            </Col>
          </Row>
        </Tab.Pane>
      ));

      return results;
    }

    const issuers = ratesheetByInstrument.ratesheet
      .filter((ratesheetItem) => ratesheetItem.issuer.currencies.includes(this.props.session.user.currency))
      .map((ratesheetIssuer) => {
        if (selectedIssuers && selectedIssuers.find((issuer) => issuer.id === ratesheetIssuer.issuer.id)) {
          return { ...ratesheetIssuer, selected: true };
        }

        return { ...ratesheetIssuer, selected: false };
      });

    const defaultIssuers = issuers.filter((issuer) => !issuer.isDemo);
    const demoIssuers = issuers.filter((issuer) => issuer.isDemo);

    const defaultPayload = this.getRatesheetPayload({
      isFetching: ratesheets.isFetching,
      highlightedColumn,
      issuers: defaultIssuers,
      type: ratesheetTabTypes.default,
    });
    const demoPayload =
      demoIssuers.length &&
      this.getRatesheetPayload({
        isFetching: ratesheets.isFetching,
        highlightedColumn,
        issuers: demoIssuers,
        type: ratesheetTabTypes.demo,
      });

    const ratesheetPanes = [];

    ratesheetPanes.push(
      <Tab.Pane key="1" eventKey="1">
        <ResultsPresenter {...defaultPayload} />
      </Tab.Pane>,
    );

    !!demoPayload &&
      ratesheetPanes.push(
        <Tab.Pane key="2" eventKey="2">
          <ResultsPresenter {...demoPayload} />
        </Tab.Pane>,
      );

    return ratesheetPanes;
  }

  getRatesheetPayload({ isFetching, highlightedColumn, issuers, type }) {
    const availableIssuersRatesheets = issuers.length
      ? issuers.filter(({ available }) => available).sort(byIssuerName)
      : [];
    const unavailableIssuersRatesheets = issuers.length
      ? issuers.filter(({ available }) => !available).sort(byIssuerName)
      : [];

    return {
      isFetching,
      noRecords: !issuers && !issuers.length,
      recordsPresenter: {
        data: [...availableIssuersRatesheets, ...unavailableIssuersRatesheets],
        ...ratesheetsRecordsPresenter,
        onSortColumnOrder: this.onSortColumnOrder,
        highlightedColumn,
        options: {
          tenantIssuerId: this.props.session.user.tenantIssuerId,
          readOnlyIssuers: this.state.readOnlyIssuers,
        },
        actions: {
          onRatesheetIssuerSelectChange: this.onRatesheetIssuerSelectChange.bind(
            this,
            ratesheetTabTypes.ratesheetsTab,
            type,
          ),
        },
      },
    };
  }

  render() {
    const { rfq, localReducer } = this.props;

    const isLoadingIssuers = !rfq.issuers || !rfq.issuers.list;

    return (
      <div className="rfq-container">
        <div
          className={classnames('rfq-header', {
            'no-scroll': isLoadingIssuers,
          })}
        >
          {this.renderHeaderSummary(rfq)}
          {this.renderTradeSettingsForm(isLoadingIssuers)}
        </div>
        <div className="rfq-body">
          {this.renderMaturities(isLoadingIssuers)}
          {this.renderTabContainer(rfq, isLoadingIssuers)}
        </div>
      </div>
    );
  }
}

const byIssuerName = (firstIssuer, secondIssuer) =>
  String(firstIssuer.issuer.name).localeCompare(secondIssuer.issuer.name);

RfqPage.propTypes = {
  actions: PropTypes.shape().isRequired,
  form: PropTypes.shape(),
  holding: PropTypes.shape().isRequired,
  issuers: PropTypes.shape().isRequired,
  licences: PropTypes.arrayOf(PropTypes.string),
  localActions: PropTypes.shape(),
  longTermComplianceOnly: PropTypes.bool.isRequired,
  localReducer: PropTypes.shape(),
  onConfirmClick: PropTypes.func,
  params: PropTypes.shape().isRequired,
  requestQuotes: PropTypes.func,
  rfq: PropTypes.shape().isRequired,
  route: PropTypes.shape(),
  segmentCode: PropTypes.string,
  session: PropTypes.shape(),
  setIsDirty: PropTypes.func,
  setStateCallback: PropTypes.func,
  tenant: PropTypes.string.isRequired,
  toggleConfirmDialog: PropTypes.func,
  maturing: PropTypes.shape(),
  maxRfqIssuerLimit: PropTypes.number.isRequired,
  holdingSummary: PropTypes.shape().isRequired,
};

export default compose(
  withConfirmDialog(),
  includeLocalReducer(rfqLocalPayloadReducer, rfqLocalPayloadActions),
  withPreventNavigation(),
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withNavigate,
  withParams,
  errorHandler((state) => ({ error: state.rfq.error })),
)(RfqPage);

const buildIssuerComplianceBreachMessage = ({ compliances, issuerName, rawPrincipal }) => {
  if (!compliances || (!compliances.short && !compliances.long)) {
    return `- ${issuerName}\n`;
  }

  const principal = rawPrincipal ? +rawPrincipal.replace(/[^0-9.]/g, '') : 0;

  const hasShortTermComplianceBreach = hasComplianceBreach({ compliances, type: 'short', principal });
  const hasLongTermComplianceBreach = hasComplianceBreach({ compliances, type: 'long', principal });

  if (!hasShortTermComplianceBreach && !hasLongTermComplianceBreach) {
    return `- ${issuerName} - Check compliances page\n`;
  }

  const shortMessage = hasShortTermComplianceBreach ? ' Short' : '';
  const isShortAndLongMessage = hasShortTermComplianceBreach && hasLongTermComplianceBreach ? ' and' : '';
  const longMessage = hasLongTermComplianceBreach ? ' Long' : '';

  return `- ${issuerName} -${shortMessage}${isShortAndLongMessage}${longMessage} Term\n`;
};

const hasComplianceBreach = ({ compliances, type, principal }) => {
  const hasRatingDefined = compliances && compliances[type];

  return hasRatingDefined === 0 ? true : compliances[type] < principal;
};
